<ion-header translucent>
  <ion-toolbar>
    <ion-title title="Create Encryption Settings...">Create Encryption Settings...</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleSaveClick()">Create Encryption Settings</ion-button>
      <ion-button (click)="handleCancelClick()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #formContainer fullscreen>
  <ion-item-group>
    <ion-item-divider>
      <ion-text color="danger">{{ validationErrors.length > 0 ? 'Errors: ' : ''}}{{ validationErrors.join('\n') }}</ion-text>
    </ion-item-divider>
    <ion-item>
      <ion-text color="primary" position="start">
        Encryption here is NOT guaranteed. That's rather the point...
      </ion-text>
      <ion-button position="end" (click)="handleShowHelpClick()">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item *ngIf="showHelp">
      <ion-text color="primary">
        <p>
          I don't believe in absolute security, and certainly not in any
          computer's or algorithm's infallibility. Ibgib is a shift
          back to a relationship with software that does not make this
          assumption. (Indeed, soon we will have AIs who instead of
          speaking "Truth" will be giving opinions that we should take
          with the same grain of salt that we do with anything.)
        </p>
        <p>
          That said, I still take security seriously and endeavor to
          strengthen the system as well as possible, giving as much
          flexibility in the security/convenience tradeoff as I can.
        </p>
        <p> But
          <a href="https://www.npmjs.com/package/encrypt-gib"
            target="_blank"><span>encrypt-gib</span></a>
            is my own custom crypto. And I would be remiss if I didn't
            state here that most crypto experts say this is a bad idea and I don't
            disagree.
        </p>
        <p>
          NOTE: There are definite improvements I have in mind to increase
          the strength of the algorithm.
          I think the hash-based alphabet approach is viable. It also fits in with
          the overall distributed architecture of ibgib data. In the long term,
          I think this design will be stronger than existing PKIs, including those
          with existing Web3 designs.
        </p>
      </ion-text>
    </ion-item>
  </ion-item-group>

  <!-- Encryption details -->
  <ion-item-group>
    <ion-item-divider>
      <ion-label>Encryption Settings Details: </ion-label>
    </ion-item-divider>

    <!-- name -->
    <ion-item [title]="fields.name.description" >
      <ion-label
        position="floating">{{ fields.name.label }}: </ion-label>
      <ion-input [(ngModel)]="name"
        [placeholder]="fields.name.placeholder"
        ></ion-input>
    </ion-item>

    <!-- description -->
    <ion-item [title]="fields.description.description">
      <ion-label position="floating">{{fields.description.label}}: </ion-label>
      <ion-input [(ngModel)]="description"
        [placeholder]="fields.description.placeholder"
        ></ion-input>
    </ion-item>

    <!-- method -->
    <ion-item>
      <ion-label>{{fields.method.label}}: </ion-label>
      <ion-select slot="end" [(ngModel)]="method" (ionChange)="method = castToAny($event).detail.value">
        <!-- FOR SOME REASON, THE BINDING WON'T WORK? -->
        <!-- maybe related to https://github.com/ionic-team/ionic-framework/issues/19324 -->
        <!-- <ion-select-option *ngFor="let item of secretTypes" [value]="item">
          <ion-label>{{item}}</ion-label>
        </ion-select-option> -->
        <ion-select-option value='encrypt-gib (weak)'>
          <ion-label>encrypt-gib (weak)</ion-label>
        </ion-select-option>
      </ion-select>
    </ion-item>

    <!-- userSalt -->
    <ion-item [title]="fields.userSalt.description">
      <ion-label position="floating">{{fields.userSalt.label}}: </ion-label>
      <ion-input [(ngModel)]= "userSalt"
        [placeholder]="fields.userSalt.placeholder"
        ></ion-input>
    </ion-item>
    <!-- initialRecursions -->
    <ion-item [title]="fields.initialRecursions.description">
      <ion-label position="floating">{{fields.initialRecursions.label}}: </ion-label>
      <ion-input [(ngModel)]="initialRecursions"
        type="number" min="0" max="1000000" step="1000"></ion-input>
    </ion-item>
    <!-- recursionsPerHash -->
    <ion-item [title]="fields.recursionsPerHash.description">
      <ion-label position="floating">{{fields.recursionsPerHash.label}}: </ion-label>
      <ion-input [(ngModel)]="recursionsPerHash"
        type="number" min="0" max="1000" step="1"></ion-input>
    </ion-item>
    <!-- Hash Algorithm -->
    <ion-item [title]="fields.hashAlgorithm.description">
      <ion-label>{{fields.hashAlgorithm.label}}: </ion-label>
      <ion-select
        [value]="hashAlgorithm"
        okText="Select algorithm"
        (ionChange)="handleSelectedHashAlgorithmChange($event)"
        >
        <ion-select-option value="SHA-256"> <ion-label>SHA-256</ion-label> </ion-select-option>
        <ion-select-option value="SHA-512"> <ion-label>SHA-512</ion-label> </ion-select-option>
      </ion-select>
    </ion-item>

  </ion-item-group>
</ion-content>
