<div *ngIf="updating">
  <ion-spinner  name="circles" color="primary"></ion-spinner>
  <span>i believe...</span>
</div>

<div *ngIf="!updating && items"
  [style.border-color]="debugBorderColor"
  [style.border-width]="debugBorderWidth"
  [style.border-style]="debugBorderStyle"
  >

  <form [formGroup]="rootFormGroup" (ngSubmit)="handleSubmit_DynamicForm()"
    >
    <ion-item *ngFor="let item of items"
      class="input-item" fill="outline"
      [title]="item.description">
      <!-- label -->
      <ion-label position="stacked"><strong>{{item.required ? '*' : ''}}{{item.label}}: </strong></ion-label>

      <!-- text input -->
      <ion-input *ngIf="['text'].includes(item.dataType)"
        [formControlName]="item.uuid"
        [placeholder]="item?.placeholder"
        [value]="item.value"
        [type]="item.dataType"
        (ionChange)="handleTextChanged(castToAny($event).target.value, item)"
        [readonly]="item.readonly"
        debounce="1000"
      ></ion-input>

      <!-- textarea input -->
      <ion-textarea *ngIf="['textarea'].includes(item.dataType)"
        [formControlName]="item.uuid"
        [placeholder]="item?.placeholder"
        [value]="item.value"
        (ionChange)="handleTextChanged(castToAny($event).target.value, item)"
        [readonly]="item.readonly"
        debounce="1000"
      ></ion-textarea>
        <!-- [disabled]="item.readonly" -->

      <!-- select WITHOUT icons -->
      <ion-select *ngIf="['select'].includes(item.dataType) && item.selectOptions?.length > 0"
        [formControlName]="item.uuid"
        okText="Select"
        (ionChange)="handleSelectChange($event, item)"
        [compareWith]="compareStrings"
        >
        <ion-select-option *ngFor="let option of item.selectOptions"
          [value]="option">
          <ion-label>{{ option }}</ion-label>
        </ion-select-option>
      </ion-select>
      <!-- select WITH icons -->
      <ion-select *ngIf="['select'].includes(item.dataType) && item.selectOptionsWithIcons?.length > 0"
        [formControlName]="item.uuid"
        okText="Select"
        (ionChange)="handleSelectChange($event, item)"
        [compareWith]="compareStringsAndIcons"
        >
        <ion-select-option *ngFor="let option of item.selectOptionsWithIcons"
          [value]="option">
          <ion-icon [name]="option.icon"></ion-icon>
          <ion-label>{{ option.label }}</ion-label>
        </ion-select-option>
      </ion-select>

      <!-- toggle -->
      <ion-toggle *ngIf="['toggle'].includes(item.dataType)"
        [formControlName]="item.uuid"
        [checked]="item.value"
        >
      </ion-toggle>

      <ib-dynamic-form *ngIf="item.dataType === 'form'"
        [parentFormGroup]="rootFormGroup"
        [formControlName]="item.uuid"
        [showSubmit]="false"
        [items]="item.items"
        >
      </ib-dynamic-form>

      <ion-note *ngIf="item.errored && !getControl(item).pristine"
        slot="error">{{item.label}}: {{getError(item)}}</ion-note>
      <ion-note *ngIf="showHelp" slot="helper" color="primary">
        {{item.label}}{{item.required ? ' (required)': ''}}: {{item.description}}
      </ion-note>
    </ion-item>
    <div class="error-summary" *ngIf="hasErrors && showErrorSummary">
      <ion-text color="danger">hmm...not a huge deal, buut...</ion-text>
      <br>
      <div *ngFor="let error of validationErrors" >
        <ion-note slot="error" color="danger">{{error}}</ion-note>
        <br>
      </div>
    </div>

    <ion-button *ngIf="showSubmit" type="submit" expand="block">Submit</ion-button>
  </form>

</div>
