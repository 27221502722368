<ion-header translucent>
  <ion-toolbar>
    <ion-title title="Create Secret...">Create Secret...</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleSaveClick()">Create Secret</ion-button>
      <ion-button (click)="handleCancelClick()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #formContainer fullscreen>
  <ion-item-group>
    <ion-item-divider>
      <ion-text color="danger">
        {{ validationErrors.length > 0 ? 'Errors: ' : ''}}{{ validationErrors.join('\n') }}
      </ion-text>
    </ion-item-divider>
    <ion-item>
      <ion-text color="primary">
        Give your secret/password a name and maybe a hint/description to help
        you remember it when the time comes.
      </ion-text>
      <ion-button (click)="handleShowHelpClick()">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item *ngIf="showHelp">
      <ion-text color="primary">
        <p>
          I have a memory like a sieve & ibgib has been designed to
          help me with my memory - not to replace it. So we do
          passwords a little different.
        </p>
        <p>
          In the real world it's <strong>hard</strong> not to "reuse"
          passwords. So we're going to help you create a system of
          managing secrets through context & hints, where you can
          compose more complex secrets through your own personalized
          "secrets" vocabulary.
        </p>
        <p>
          But Beware! Life's about tradeoffs.
          The more explicit long-term hints you include,
          the easier it becomes for guys (and/or gals!) to crack.
        </p>
        <p>
          Really though, unless you have an eiditic memory, this is a start
          that will hopefully help you manage your security - especially if
          you haven't thought about "that password" for year(s)!
        </p>
        <p>
          NOTE: We'll get something akin to truer Multi-Factor Authentication going in the future.
        </p>
      </ion-text>
    </ion-item>
  </ion-item-group>
  <!-- <ion-item-group *ngIf="validationErrorsYo?.length > 0">
    <ion-item>
      <ion-label>validation errors wakka</ion-label>
    </ion-item>
    <ion-item *ngFor="let p of validationErrors">
      <ion-label>yo</ion-label>
    </ion-item>
  </ion-item-group> -->

  <!-- Secret details -->
  <ion-item-group>
    <ion-item-divider>
      <ion-label>Secret Details: </ion-label>
    </ion-item-divider>

    <!-- name -->
    <ion-item [title]="fields.name.description" >
      <ion-label
        position="floating">{{ fields.name.label }}: </ion-label>
      <ion-input [(ngModel)]="name"
        [placeholder]="fields.name.placeholder"
        ></ion-input>
    </ion-item>

    <!-- description -->
    <ion-item [title]="fields.description.description">
      <ion-label position="floating">{{fields.description.label}}: </ion-label>
      <ion-input [(ngModel)]="description"
        [placeholder]="fields.description.placeholder"
        ></ion-input>
    </ion-item>

    <!-- secretType -->
    <ion-item>
      <ion-label>{{fields.secretType.label}}: </ion-label>
      <ion-select [(ngModel)]="secretType"
        (ionChange)="handleSelectedSecretTypeChange($event)"
        >
        <!-- FOR SOME REASON, THE BINDING WON'T WORK? -->
        <!-- <ion-select-option *ngFor="let item of secretTypes" [value]="item">
          <ion-label>{{item}}</ion-label>
        </ion-select-option> -->
        <ion-select-option value='password'>
          <ion-label>password</ion-label>
        </ion-select-option>
      </ion-select>
    </ion-item>

    <!-- expirationUTC -->
    <ion-item [title]="fields.expirationUTC.description">
      <ion-label position="floating">{{fields.expirationUTC.label}}: </ion-label>
      <ion-input readonly [value]="expirationUTC"></ion-input>
    </ion-item>
    <!-- userPassword -->
    <ion-item [title]="fields.userPassword.description" class="has-visible-toggle">
      <ion-label position="floating">{{fields.userPassword.label}}: </ion-label>
      <ion-input [(ngModel)]="userPassword"
        [type]="fields.userPassword.unmasked ? 'text' : 'password'">
      </ion-input>
      <ion-button slot="end"
        (click)="fields.userPassword.unmasked = !fields.userPassword.unmasked">
        <ion-icon *ngIf="fields.userPassword.unmasked" name="eye-off-outline"></ion-icon>
        <ion-icon *ngIf="!fields.userPassword.unmasked" name="eye-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <!-- userPasswordConfirm -->
    <ion-item [title]="fields.userPasswordConfirm.description" class="has-visible-toggle">
      <ion-label position="floating">{{fields.userPasswordConfirm.label}}: </ion-label>
      <ion-input [(ngModel)]="userPasswordConfirm"
        [type]="fields.userPasswordConfirm.unmasked ? 'text' : 'password'">
      </ion-input>
      <ion-button slot="end"
        (click)="fields.userPasswordConfirm.unmasked = !fields.userPasswordConfirm.unmasked">
        <ion-icon *ngIf="fields.userPasswordConfirm.unmasked" name="eye-off-outline"></ion-icon>
        <ion-icon *ngIf="!fields.userPasswordConfirm.unmasked" name="eye-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <!-- hint -->
    <ion-item [title]="fields.hint.description">
      <ion-label position="floating">{{fields.hint.label}}: </ion-label>
      <ion-input [(ngModel)]="hint"
        [placeholder]="fields.hint.placeholder"
        type="text"></ion-input>
    </ion-item>

  </ion-item-group>
</ion-content>
