<ion-header translucent>
  <ion-toolbar>
    <ion-title title="Update Pic 📷...">Update Pic 📷...</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleSaveClick()">Save Updated Pic</ion-button>
      <ion-button (click)="handleCancelClick()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #formContainer fullscreen>
  <ion-item-group>
    <ion-item-divider>
      <ion-text color="danger">{{ validationErrors.length > 0 ? "Errors: " : ""}}{{ validationErrors.join("\n") }}</ion-text>
    </ion-item-divider>
    <ion-item>
      <ion-text color="primary" position="start">
        Update the image for things like timelapses & before/afters pics.
      </ion-text>
      <ion-button position="end" (click)="handleShowHelpClick()">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item *ngIf="showHelp">
      <ion-text color="primary">
        <p>
          If doing a timelapse or before/after pic, be sure to try to get the same angle 📷
        </p>
      </ion-text>
    </ion-item>
  </ion-item-group>

  <!-- Update details -->
  <ion-item-group>

    <!-- updated pic section -->
    <ion-item-divider>
      <ion-label>Updated Pic Image: </ion-label>
    </ion-item-divider>

    <!-- updated pic img -->
    <ion-item>
      <ion-card fullscreen>
        <img *ngIf='updatedItem?.picSrc' [src]='updatedItem?.picSrc'/>
        <ion-card-header>
          <ion-card-content>
            <ion-toolbar>
              <ion-buttons>
                <!-- Pick image from file input -->
                <ion-button
                  color="primary"
                  (click)='inputFile.click()'>
                  <ion-icon slot="start" name="image-outline"></ion-icon>
                  <ion-label>file...</ion-label>
                  <ion-spinner *ngIf="handlingInputFileClick" name="circles" color="tertiary"></ion-spinner>
                  <input #inputFile type="file" hidden (change)='filepicked($event)'/>
                </ion-button>
                <!-- Get image from camera capture -->
                <ion-button
                  color="primary"
                  (click)='inputFileCapture.click()'>
                  <ion-icon slot="start" name="camera-outline"></ion-icon>
                  <ion-label>capture...</ion-label>
                  <ion-spinner *ngIf="handlingInputFileClick" name="circles" color="tertiary"></ion-spinner>
                  <input #inputFileCapture type="file" hidden (change)='filepicked($event)' accept="image/*" capture/>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-content>
          <ion-card-subtitle class='pic-timestamp'>
              {{ updatedItem?.timestamp }}
          </ion-card-subtitle>

        </ion-card-header>
      </ion-card>
    </ion-item>

    <!-- current pic section -->
    <ion-item-divider>
      <ion-label>Current Pic Image: </ion-label>
    </ion-item-divider>

    <ion-item *ngIf="addr">
      <pic-view
        class="pic-item"
        [addr]="addr"
        [isTitleOrJustSmall]="false"
        (click)="handlePicClicked()"
        [style.border-color]="debugBorderColor"
        [style.border-width]="debugBorderWidth"
        [style.border-style]="debugBorderStyle"
        >
      </pic-view>
    </ion-item>
    <ion-item *ngIf="!addr">
      <p>...</p>
      <ion-spinner name="circles" color="tertiary"></ion-spinner>
    </ion-item>

    <!-- name -->
    <ion-item [title]="fields.name.description" >
      <ion-label
        position="floating">{{ fields.name.label }}: </ion-label>
      <ion-input [(ngModel)]="name"
        [placeholder]="fields.name.placeholder"
        readonly
        ></ion-input>
    </ion-item>

    <!-- extension -->
    <ion-item [title]="fields.extension.description" >
      <ion-label
        position="floating">{{ fields.extension.label }}: </ion-label>
      <ion-input [(ngModel)]="extension"
        [placeholder]="fields.extension.placeholder"
        readonly
        ></ion-input>
    </ion-item>

    <!-- binHash -->
    <ion-item [title]="fields.binHash.description" >
      <ion-label
        position="floating">{{ fields.binHash.label }}: </ion-label>
      <ion-input [(ngModel)]="binHash"
        [placeholder]="fields.binHash.placeholder"
        readonly
        ></ion-input>
    </ion-item>

    <!-- timestamp -->
    <ion-item [title]="fields.timestamp.description" >
      <ion-label
        position="floating">{{ fields.timestamp.label }}: </ion-label>
      <ion-input [(ngModel)]="timestamp"
        [placeholder]="fields.timestamp.placeholder"
        readonly
        ></ion-input>
    </ion-item>

  </ion-item-group>
</ion-content>
